<template>
  <div class="row">
    <div class="col-md-12">
      <h2 class="fw-bolder">
        <a
          href="javascript:void(0)"
          class="text-black fas fa-angle-left"
          style="margin-right: 15px"
          @click="goBack"
        >
        </a
        >{{ title }}
      </h2>
    </div>
  </div>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body p-9">
      <div class="row mb-7" v-for="(field, index) in fields" :key="index">
        <!--begin::Label-->
        <label class="col-lg-6 fw-bold text-muted">{{ field.label }}</label>
        <!--end::Label-->
        <!--begin::Col-->
        <template v-if="field.bool">
          <template v-if="models[field.key] != undefined">
            <div class="col-lg-6">
              <span
                :class="
                  models[field.key]
                    ? 'badge badge-lg badge-success'
                    : 'badge badge-lg badge-danger'
                "
                >{{ models[field.key] ? "Aktif" : "Tidak Aktif" }}</span
              >
            </div>
          </template>
          <hr v-if="field.row" class="my-2" />
        </template>
        <template v-else-if="field.bool2">
          <template v-if="models[field.key] != undefined">
            <div class="col-lg-6">
              <span
                :class="
                  models[field.key]
                    ? 'badge badge-lg badge-success'
                    : 'badge badge-lg badge-danger'
                "
                >{{ models[field.key] ? "Aktif" : "Non Aktif" }}</span
              >
            </div>
          </template>
          <hr v-if="field.row" class="my-2" />
        </template>
        <template v-else-if="field.link">
          <div class="col-lg-6">
            <template v-if="models[field.key] == null"> - </template>
            <template v-else>
              <a
                href="javascript:void(0)"
                @click="openFile(models[field.key])"
                title="Lihat File"
                >Lihat File</a
              >
            </template>
          </div>
          <hr v-if="field.row" class="my-2" />
        </template>
        <template v-else-if="field.spasi">
          <div class="mt-5 mb-5"></div>
        </template>
        <template v-else-if="field.custom">
          <div class="col-lg-6">
            <slot :name="field.key">
              <span class="fw-bolder fs-6 text-dark">{{
                models[field.key]
              }}</span>
            </slot>
          </div>
          <hr v-if="field.row" class="my-2" />
        </template>
        <template v-else-if="field.custom2"> </template>
        <template v-else>
          <div class="col-lg-6">
            <span class="fw-bolder fs-6 text-dark">{{
              models[field.key] == null ? "-" : models[field.key]
            }}</span>
          </div>
          <hr v-if="field.row" class="my-2" />
        </template>
        <!--end::Col-->
      </div>
      <div class="mb-7" v-for="(field, index) in fields" :key="index">
        <template v-if="field.custom2">
          <slot :name="field.key"></slot>
        </template>
      </div>
      <!--end::Row-->
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-dark mr-2"
          v-if="tvalid.update"
          @click="btnEdit"
        >
          Ubah</button
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" class="btn btn-light" @click="goBack">
          Kembali
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: {
    title: { type: String, default: "" },
    fields: { type: Array, default: [] },
    models: { type: Object, default: {} },
    nameroute: { type: String, default: "" },
    printurl: { type: String, default: "" },
    edit: { type: Boolean, default: true },
  },
  data() {
    return {
      pageLink: [],
      tvalid: {
        create: false,
        delete: false,
        update: false,
        list: false,
        detail: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    this.pageLink = this.$store.state.BodyModule.isPageChild;
    for (var i in this.pageLink) {
      let row = this.pageLink[i];
      if (row == "Create") {
        this.tvalid.create = true;
      } else if (row == "Data Table") {
        this.tvalid.list = true;
      } else if (row == "Detail") {
        this.tvalid.detail = true;
      } else if (row == "Update") {
        if (this.edit) {
          this.tvalid.update = true;
        }
      } else {
        this.tvalid.delete = true;
      }
    }
    setTimeout(() => {
      this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    }, 500);
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    openFile(link) {
      this.$apiservice.setHeader();
      this.$apiservice
        .query("/reference/v1/get-file", { params: { Url: link } })
        .then((res) => {
          window.open(res.request.responseURL, "_blank");
        });
    },
    btnEdit() {
      this.$router.push({
        name: this.nameroute + "-edit",
        params: { id: this.$route.params.id },
      });
    },
    btnBack() {
      this.$router.push({ name: this.nameroute });
    },
  },
};
</script>
