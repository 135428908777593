<template>
    <basedetail v-if="refreshComp" :title="title" :models="model" :fields="field" :nameroute="nameroute" :edit="false">
        <template v-slot:vendor_type_id>
            <span class="fw-bolder fs-6 text-dark">{{ model.vendor_type_id }}</span>
        </template>
        <template v-slot:using_ppn>
            <span class="fw-bolder fs-6 text-dark">{{ model.using_ppn === false ? 'Tidak' : 'Ya' }}</span>
        </template>
        <template v-slot:ppn>
            <span class="fw-bolder fs-6 text-dark">{{ model.ppn }} %</span>
        </template>
        <template v-slot:po_type>
            <span class="fw-bolder fs-6 text-dark">{{ model.po_type_label }}</span>
        </template>
        <template v-slot:delivery_branch_id>
            <span class="fw-bolder fs-6 text-dark">{{ model.delivery_branch_id + ' - ' + model.delivery_branch_name }}</span>
        </template>
        <template v-slot:delivery_department_code>
            <span class="fw-bolder fs-6 text-dark">{{
                model.delivery_department_code + ' - '+model.delivery_department_name}}</span>
        </template>
        <template v-slot:delivery_vendor_id>
            <span class="fw-bolder fs-6 text-dark">{{ model.delivery_vendor_name }}</span>
        </template>
        <template v-slot:print>
            <button type="button" class="float-end btn btn-dark mr-2" :data-kt-indicator="loading ? 'on' : null"
                ref="tPrint" @click="btnPrint">
                <span class="indicator-label"><i class="fa fa-print"></i> Print </span>
                <span class="indicator-progress">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
            <br><br><br>
        </template>
        <template v-slot:department_name>
            <span class="fw-bolder fs-6 text-dark">{{ model.department_name }}</span>
        </template>

        <template v-slot:tabel>
            <Datatable v-if="refreshTable" ref="basetable" :table-header="tabelHeader" :listData="listData">
                <template v-slot:cell-pr_code="{ row: data }">
                    {{ data.pr_code }}
                </template>
                <template v-slot:cell-branch_name="{ row: data }">
                    {{ data.branch }}
                </template>
                <template v-slot:cell-departement_name="{ row: data }">
                    {{ data.departement }}
                </template>
                <template v-slot:cell-updated_date="{ row: data }">
                    {{ $mix.format_date_time(data.updated_date) }}
                </template>
                <template v-slot:cell-product_name="{ row: data }">
                    {{ data.product_name }}
                </template>
                <template v-slot:cell-product_id="{ row: data }">
                    {{ data.product_id }}
                </template>
                <template v-slot:cell-asset="{ row: data }">
                    {{ data.asset }}
                </template>
                <template v-slot:cell-lob_name="{ row: data }">
                    {{ data.lob_name }}
                </template>
                <template v-slot:cell-stock_on_hand="{ row: data }">
                    {{ data.stock_on_hand }}
                </template>
                <template v-slot:cell-unit_price="{ row: data }">
                    {{ $mix.formatCurrency(data.unit_price, 'IDR') }}
                </template>
                <template v-slot:cell-unit_id="{ row: data }">
                    {{ data.unit_id }}
                </template>
                <template v-slot:cell-sub_total="{ row: data }">
                    {{ $mix.formatCurrency(data.sub_total, 'IDR') }}
                </template>
                <template v-slot:cell-qty="{ row: data }">
                    {{ data.qty }} &nbsp;&nbsp;&nbsp; 
                    <!-- <a href="javascript:void(0)" -->
                        <!-- @click="btnEditStok(data.id_data)"><span class="fa fa-edit"></span></a> -->
                </template>
                <template v-slot:cell-action="{ row: data }">
                    <button type="button" v-bind:id="'btnHapus' + data.id_data" @click="deletePR(data.id_data)"
                        class="btn btn-sm btn-light-info"><span class="fa fa-trash"></span></button>&nbsp;
                </template>
            </Datatable>
        </template>
        <template v-slot:list_total>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6 px-4 mb-7">
                        <div class="row">
                            <label class="col-lg-6 fw-bold text-muted">Diskon</label>
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-dark">{{
                                    $mix.formatCurrency(model.discount, 'IDR')
                                }}</span>
                            </div>
                            <hr class="my-2">
                        </div>
                    </div>
                    <div class="col-md-6 px-4 mb-7">
                        <div class="row">
                            <label class="col-lg-6 fw-bold text-muted">Nilai Pengajuan</label>
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-dark">{{
                                    $mix.formatCurrency(model.grand_total, 'IDR')
                                }}</span>
                            </div>
                            <hr class="my-2">
                        </div>
                    </div>
                    <div class="col-md-6 mb-7">
                        <div class="row">
                            <label class="col-lg-6 fw-bold text-muted">Total Harga Pengajuan</label>
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-dark">{{
                                    $mix.formatCurrency(model.total_after_ppn, 'IDR')
                                }}</span>
                            </div>
                            <hr class="my-2">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:approval>
            <hr class="my-2">
            <h4 class="mb-6">Approval Status</h4>
            <div class="col-md-12 mb-6">
                <label class="col-lg-6 fw-bold text-muted">Catatan Persetujuan</label>
                <textarea name="approval" rows="3" maxlength="255" placeholder="Masukkan Catatan"
                    v-model="approval.note" class="form-control"></textarea>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-success mr-2"
                    @click="btnApprove('STAPP')">Disetujui</button>&nbsp;&nbsp;&nbsp;&nbsp;
                <!-- <button type="button" class="btn btn-warning mr-2" @click="btnApprove('STRTN')">Butuh Revisi</button>&nbsp;&nbsp;&nbsp;&nbsp; -->
                <button type="button" class="btn btn-danger" @click="btnApprove('STRJT')">Tolak</button>
            </div>
        </template>
        <template v-slot:riwayat>
            <hr class="my-2">
            <h4 class="mb-6">Riwayat Aproval Purchase Order</h4>
            <Datatable v-if="refreshTableHistory" ref="basetablehistory" :table-header="tabelHeaderHistory"
                :listData="listDataHistory">
                <template v-slot:cell-approval_status_description="{ row: data }">
                    {{ data.approval_status_description }}
                </template>
                <template v-slot:cell-created_by="{ row: data }">
                    {{ data.created_by }}
                </template>
                <template v-slot:cell-next_approval_name="{ row: data }">
                    {{ data.next_approval_name }}
                </template>
                <template v-slot:cell-po_date="{ row: data }">
                    {{ $mix.format_date(data.po_date) }}
                </template>
                <template v-slot:cell-reason="{ row: data }">
                    {{ data.reason }}
                </template>
            </Datatable>
        </template>

        <template v-slot:tabelNew>
            <hr class="my-2">
            <Datatable v-if="refreshTableDetailNew" ref="basetabledetailnew" :table-header="tabelHeaderDetailNew"
                :listData="listDataDetailNew">
                <template v-slot:cell-po_code_pengambilan="{ row: data }">
                    {{ data.po_code_pengambilan }}
                </template>
                <template v-slot:cell-branch="{ row: data }">
                    {{ data.branch }}
                </template>
                <template v-slot:cell-department="{ row: data }">
                    {{ data.department }}
                </template>
                <template v-slot:cell-po_date="{ row: data }">
                    {{ $mix.format_date(data.po_date) }}
                </template>
            </Datatable>
        </template>
    </basedetail>
    <template>
        <div id="divCon">  
        </div>
    </template>
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import basedetail from '@/views/base/BaseDetail.vue';
import Datatable from "@/components/kt-datatable/KTDatatableNoPagination.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
    components: {
        basedetail,
        Datatable
    },
    data() {
        const list = [
            { key: 'pr_code', name: 'NOMOR MASTER PURCHASE REQUEST', sortable: true },
            { key: 'branch_name', name: 'Cabang', sortable: true },
            { key: 'departement_name', name: 'Departemen', sortable: true },
            { key: 'updated_date', name: 'TANGGAL DISETUJUI', sortable: true },
            { key: 'product_name', name: 'Nama PRODUK', sortable: true },
            { key: 'product_id', name: 'ID PRODUK', sortable: true },
            { key: 'asset', name: 'Asset - Kategori', sortable: true },
            { key: 'unit_price', name: 'Harga Satuan', sortable: true },
            { key: 'unit_id', name: 'Satuan', sortable: true },
            { key: 'stock_on_hand', name: 'Stock On Hand', sortable: true },
            { key: 'lob_name', name: 'LoB', sortable: true },
            { key: 'qty', name: 'Order Qty', sortable: true },
            { key: 'sub_total', name: 'Total Harga', sortable: true },
        ]
        const listHistory = [
            { key: 'approval_status_description', name: 'Status Persetujuan', sortable: true },
            { key: 'created_by', name: 'PIC', sortable: true },
            { key: 'next_approval_name', name: 'Persetujuan Selanjutnya', sortable: true },
            { key: 'po_date', name: 'Waktu Persetujuan', sortable: true },
            { key: 'reason', name: 'Note Persetujuan', sortable: true },
        ]
        const listDetailNew = [
            { key: 'po_code_pengambilan', name: 'NO PO', sortable: true },
            { key: 'branch', name: 'CABANG', sortable: true },
            { key: 'department', name: 'DEPARTEMEN', sortable: true },
            { key: 'po_date', name: 'TANGGAL DISETUJUI', sortable: true },
        ]
        return {
            title: 'Rincian List Master PO',
            nameroute: 'procurement-master-purchase-order',
            tabelHeader: list,
            tabelHeaderHistory: listHistory,
            tabelHeaderDetailNew: listDetailNew,
            field: [
                { key: 'print', custom: true, row: true },
                { key: 'po_code', label: 'Nomor Purchase Order', row: true },
                { key: 'department_name', label: 'Departemen Pengadaan', row: true, custom: true },
                { key: 'po_type', label: 'Jenis PO', row: true, custom: true },
                { key: 'vendor_type_id', label: 'Tipe Vendor', row: true, custom: true },
                // {key:'po_code_pengambilan',label:'PO Code Master', row:true},
                { spasi: true },
                { key: 'using_ppn', label: 'PPn', row: true, custom: true },
                { key: 'vendor_name', label: 'Nama Vendor', row: true },
                { key: 'jenis_pajak_name', label: 'PPh', row: true, custom: true },
                { key: 'jenis_perhitungan_pajak', label: 'Jenis Perhitungan Pajak', row: true },
                { key: 'nomor_rekening', label: 'Nomor Rekening', row: true },
                { key: 'bank_name', label: 'Bank', row: true },
                { key: 'kantor_cabang_bank', label: 'Cabang Bank', row: true },
                { key: 'vendor_phone', label: 'Telepon', row: true },
                { key: 'vendor_address', label: 'Alamat', row: true },
                { key: 'reason', label: 'Catatan', row: true },

                // {key:'delivery_branch_id',label:'Cabang', row:true, custom:true},
                // {key:'delivery_department_code',label:'Departemen Pengiriman', row:true, custom:true},
                // {key:'delivery_vendor_id',label:'Gudang', row:true, custom:true},

                { key: 'tabelNew', custom2: true, row: true },

                // {key:'tabel',custom2:true, row:true},
                // {key:'list_total',custom2:true, row:true},
                // {key: 'approval', custom2: true, row: true },
                // {key: 'riwayat', custom2: true, row: true },

            ],
            listData: [],
            listDataHistory: [],
            listDataDetailNew: [],
            refreshComp: true,
            refreshTable: true,
            refreshTableHistory: true,
            refreshTableDetailNew: true,
            loading: false,
            model: {
                ppn: 0,
                ppn_total: 0,
                grand_total: 0,
                total_after_ppn: 0
            },
            listDept: [],
            listBranch: [],
            listVendorType: [],
            profile: JSON.parse(this.$store.getters.currentUser),
            approval: {
                po_code: '',
                approval_status: '',
                note: '',
            },
            lsPoType: [],
            lsDelivery: [],
        }
    },
    mounted() {
        setCurrentPageBreadcrumbs('Rincian List Approval Master PO', [
            {
                title: "Procurement",
                link: '#'
            },
            {
                title: "Master PO",
                link: '/procurement/mstr-purchase-order'
            }
        ])
        this.fetchData()
        this.fetchPoType()
        this.fetchDelivery()
    },
    methods: {
        changePoType(val) {
            let dt = this.lsPoType
            let dtf = dt.filter(data => data.value == val)
            this.model['po_type_label'] = dtf[0].label
            this.forceUpdate()
        },
        getVendorType(val) {
            // this.$apiopex.post('/vendor/v1/get-vendor-type-autocomplete', { q: '', limit: 100 }).then(res => {
            //     let data = res.data.data
            //     this.listVendorType = []
            //     for (var i in data) {
            //         this.listVendorType.push({
            //             value: data[i].id,
            //             label: data[i].text
            //         })
            //     }
            //     this.changePoType(this.model.po_type)
            // }).finally(() => {
            //     let cari = JSON.parse(JSON.stringify(this.listVendorType)).filter(datas => datas.value == val)
            //     if (cari.length > 0) {
            //         this.model.vendor_type_id = cari[0].label
            //     }
            // })
        },
        btnPrint() {
            this.loading = true
            this.$refs.tPrint.setAttribute("data-kt-indicator", "on");
            this.$refs.tPrint.disabled = true;
            this.$apiservice.setHeader();
            this.$apiservice.query('/purchaseorder/v1/print', { params: { poCode: this.$route.params.id } }).then(res => {
                var contentOfDiv = document.getElementById("divCon").innerHTML;
                var newWin = window.open('', '', 'height=650, width=1200');
                newWin.document.write(contentOfDiv);
                newWin.document.write(res.data);
                setTimeout(function () {
                  newWin.document.close();
                  newWin.focus();
                  newWin.print();
                  newWin.close();
                }, 1000);
            }).finally(() => {
                this.loading = false
                this.$refs.tPrint.removeAttribute("data-kt-indicator");
                this.$refs.tPrint.disabled = false;
            })
        },
        btnApprove(status) {
            this.approval.approval_status = status
            this.$apiservice.setHeader();
            this.$apiservice.post('/purchaseorder/v1/approval', this.approval).then(res => {
                let data = res.data
                if (this.approval.note != '') {
                    if (data.error) {
                        Swal.fire({
                            text: data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Oke",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            this.approval.note = ''
                            this.forceUpdateTableHistory()
                        })
                    } else {
                        Swal.fire({
                            text: 'Approval berhasil tersimpan',
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Oke",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            this.$router.push({ name: this.nameroute })
                        })
                    }
                } else {
                    Swal.fire({
                        text: 'Catatan Persetujuan tidak boleh kosong',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Oke",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    })
                }
            })
        },
        goBack() {
            return this.$router.go(-1)
        },
        fetchData() {
            this.$apiservice.setHeader();
            this.$apiservice.query('/purchaseorder/v1/master-detail', { params: { poCode: this.$route.params.id } }).then(res => {
                this.model = {}
                let data = res.data
                for (var i in this.field) {
                    let row = this.field[i]
                    for (var j of Object.keys(data.header)) {
                        if (row.key == j) {
                            this.model[j] = data.header[j]
                        }
                    }
                }
                for (var j of Object.keys(data.header)) {
                    for (var i of Object.keys(this.approval)) {
                        if (i == j) {
                            if (i == 'reason') {
                                this.approval['reason'] = ''
                            } else {
                                this.approval[i] = data.header[i]
                            }
                        }
                    }
                }
                if (data.details.length > 0) {
                    this.model.po_details = []
                    this.listData = []
                    for (var i in data.details) {
                        let row = data.details[i]
                        row['asset'] = row.asset_code + '-' + row.asset_name
                        row['id_data'] = data.details.length + 1
                        this.listData.push({ ...row })
                    }
                    this.model.po_details = this.listData
                    this.approval.po_details = this.listData
                }

                this.listDataHistory = data.approvals
                this.listDataDetailNew = data.details
                this.model['ppn'] = data.header['ppn']
                this.model['discount'] = data.header['discount']
                this.model['grand_total'] = data.header['grand_total']
                this.model['ppn_total'] = data.header['ppn'] / 100 * data.header['grand_total']
                this.model['total_after_ppn'] = data.header['total_after_ppn']
                this.approval['modified_by'] = this.profile.data.username
                this.model['modified_by'] = this.profile.data.username
                this.model['delivery_branch_name'] = data.header['delivery_branch_name']
                this.model['delivery_department_name'] = data.header['delivery_department_name']
                this.model['delivery_vendor_name'] = data.header['delivery_vendor_name']
                this.getVendorType(this.model.vendor_type_id)

            }).catch(err => {
                if (err.response.status == 401) {
                    this.$router.push({ name: '401' })
                } else if (err.response.status == 404) {
                    this.$router.push({ name: '404' })
                } else if (err.response.status == 500) {
                    this.$router.push({ name: '500' })
                }
            })
        },
        cariBranch(key) {
            this.$apihris.get('/master/v2/list-subdepartment-group').then(res => {
                let data = res.data.data
                this.listBranch = []
                for (var i in data) {
                    this.listBranch.push({
                        value: data[i].sub_department_group_code,
                        label: data[i].sub_department_group_name,
                    })
                }
                let list = this.listBranch
                let find = list.filter(data => data.value == key)
                this.model['branch_name'] = find[0].label
            })
        },
        cariDepartment(key) {
            this.$apiservice.setHeader()
            this.$apiservice.get('/department/v1/get-all').then(res => {
                let data = res.data
                this.listDept = []
                for (var i in data) {
                    this.listDept.push(
                        {
                            value: data[i].department_code,
                            label: data[i].department_name
                        }
                    )
                }
                let list = this.listDept
                let find = list.filter(data => data.value == key)
                this.model['department_name'] = find[0].label
            })
        },
        convertOnTime(val) {
            if (val == null || val == 0) {
                val = 0
            } else {
                val = 1
            }
        },
        fetchPoType() {
            this.$apiservice.get('/reference/v1/get-po-types').then(res => {
                let data = res.data
                this.lsPoType = []
                for (var i in data) {
                    this.lsPoType.push({
                        value: data[i].id,
                        label: data[i].text
                    })
                }
                this.model.po_type = 'MSTR'
            })
        },
        fetchDelivery() {
            this.$apiservice.get('/reference/v1/get-delivery-types').then(res => {
                let data = res.data
                this.lsDelivery = []
                for (var i in data) {
                    this.lsDelivery.push({
                        value: data[i].id,
                        label: data[i].text
                    })
                }
            })
        },
        forceUpdateTable() {
            this.refreshTable = false
            this.$nextTick(() => {
                this.refreshTable = true
            })
        },
        forceUpdate() {
            this.refreshComp = false
            this.$nextTick(() => {
                this.refreshComp = true
            })
        }
    },
}
</script>